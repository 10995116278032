import { Button } from '@mui/material';
// import { useConfirm } from 'material-ui-confirm';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ScreenHeader from 'src/components/layout/ScreenHeader';
import { designSevenPagePath } from 'src/designSevenPagePath';
// import { IFilterProps, TFilterID } from 'src/reducers/FilterReducer/FilterReducer';
import { MoveHours } from 'src/screens/MoveHours';
import { ResourcePlanner } from 'src/screens/ResourcePlanner';
// import { revenueForecastSelectedFilterListStateKey } from 'src/screens/RevenueForecast/localStorageKeys';
import { SearchProjects } from 'src/screens/SearchProjects';
// import { safeParseJson } from 'src/utils/object';
import PageRoute from '../PageRoute';

// const getSelectedFilterFromLocalStorage = (): Record<TFilterID, IFilterProps> => {
//   const filterFromLocalStorage: Record<TFilterID, IFilterProps> =
//     safeParseJson(localStorage.getItem(revenueForecastSelectedFilterListStateKey) || '') || {};
//   const filterFromLocalStorageResourcePlanner: Record<TFilterID, IFilterProps> =
//     safeParseJson(localStorage.getItem(RPSelectedFilterListStateKey) || '') || {};

//   // If there are overlapping filters, take the ones from resource planner
//   Object.keys(filterFromLocalStorageResourcePlanner).forEach((key) => {
//     if (filterFromLocalStorage[key]) {
//       filterFromLocalStorage[key] = filterFromLocalStorageResourcePlanner[key];
//     }
//   });

//   // If there are filters in resource planner that are not in revenue forecast, add them
//   Object.keys(filterFromLocalStorageResourcePlanner).forEach((key) => {
//     if (!filterFromLocalStorage[key]) {
//       filterFromLocalStorage[key] = filterFromLocalStorageResourcePlanner[key];
//     }
//   });

//   Object.keys(filterFromLocalStorage).forEach((key) => {
//     if (Object.keys(filterFromLocalStorageResourcePlanner).indexOf(key) === -1) {
//       delete filterFromLocalStorage[key];
//     }
//   });
//   return filterFromLocalStorage;
// };

export const ProjectsRouter = () => {
  const { t } = useTranslation('screenHeaderTitle');
  const navigate = useNavigate();
  // const confirm = useConfirm();

  // const goToRevenueForecast = () => {
  //   confirm({
  //     title: 'Do you want to bring your filters?',
  //     // description: t('modals.AddCompetenceCloseWarningText'),
  //     dialogProps: {
  //       maxWidth: 'xs',
  //     },
  //     confirmationButtonProps: {
  //       variant: 'contained',
  //       size: 'small',
  //     },
  //     confirmationText: 'Yes',
  //     cancellationText: 'No',
  //     cancellationButtonProps: {
  //       variant: 'outlined',
  //       size: 'small',
  //     },
  //   })
  //     .then(() => {
  //       localStorage.setItem(
  //         revenueForecastSelectedFilterListStateKey,
  //         JSON.stringify(getSelectedFilterFromLocalStorage()),
  //       );
  //       navigate('/reports/revenue-forecast');
  //     })
  //     .catch(() => {
  //       localStorage.setItem(revenueForecastSelectedFilterListStateKey, '{}');
  //       navigate('/reports/revenue-forecast');
  //     });
  // };

  return (
    <Routes>
      <Route
        path={designSevenPagePath.projects.resourcePlanner}
        element={
          <>
            <ScreenHeader
              actions={
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => navigate('/reports/revenue-forecast')}
                >
                  {t('RevenueForecast')}
                </Button>
              }
            />
            <PageRoute
              pageTitle="ResourcePlanner"
              pageId="c210b1c5-f3df-44d8-b17c-43c10a504c0d"
              pageIdentifier="NewResourcePlanner"
            >
              <ResourcePlanner />
            </PageRoute>
          </>
        }
      />
      <Route
        path={designSevenPagePath.projects.searchProjects}
        element={
          <>
            <ScreenHeader />
            <PageRoute
              pageTitle="SearchProjects"
              pageId="c3e04a88-0a00-487f-9e59-9d101a96462f"
              pageIdentifier="DesignSevenSearchProject"
              fallbackUrl="/projectManagement/Search/SearchProjects"
              key="SearchProjectsRouteKey"
            >
              <SearchProjects />
            </PageRoute>
          </>
        }
      />

      <Route
        path={designSevenPagePath.projects.moveHours}
        element={
          <>
            <ScreenHeader />
            <PageRoute
              pageTitle="MoveHours"
              pageId="7af9b8f1-dc98-4bc7-a1a4-9081451b0d1b"
              pageIdentifier="DesignSevenMoveHours"
              fallbackUrl="/projectManagement/Adjustment/RedistributionOfTime"
              key="MoveHoursRouteKey"
            >
              <MoveHours />
            </PageRoute>
          </>
        }
      />
    </Routes>
  );
};
