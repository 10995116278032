import { useGetFilterAPI } from 'src/apis/filterAPI';
import { FilterLayout } from 'src/components/layout/FilterLayout';
import { ResponseHandler } from 'src/components/utils/ResponseHandler';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { TimeRegistrationsTable } from './Table';

const Report = () => {
  const pageIdentifier = useGetCurrentPageIdentifier();
  const { filterList, isError, isLoading } = useGetFilterAPI(pageIdentifier);

  return (
    <ResponseHandler isLoading={isLoading} isEmpty={filterList.length <= 0} isError={isError}>
      <FilterLayout filterList={filterList}>
        <TimeRegistrationsTable />
      </FilterLayout>
    </ResponseHandler>
  );
};

export default Report;
