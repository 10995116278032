export const removeEmptySpace = (str: string): string => str.replace(/\s/g, '');
export const capitalize = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1);
export const escapeSearchText = (text: string) => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

export const endWith = (compareText = '', endingText = '') => {
  if (endingText && compareText) {
    const endingRegExp = new RegExp(`${escapeSearchText(endingText)}$`, 'i');
    return endingRegExp.test(compareText);
  }
  return false;
};

export const stringToPascal = (text: string) =>
  // Add toString if text is a number or other type
  (text.toString().match(/[a-zA-Z0-9]+/g) || [])
    .map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
    .join('');

export const kebabToPascal = (text: string) =>
  text
    .split('-')
    .map((t) => t.charAt(0).toUpperCase() + t.slice(1))
    .join('');

export const pascalToKebab = (text: string) =>
  text.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
