import { Language } from 'src/apis/types/userSettingsAPI';
import { stringToPascal } from '../string';

export const monthShortNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const monthInNumber = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

export const shortMonthAndYearTranslated = (
  date: Date,
  language: Language['shortString'] = 'en',
) => {
  // We just need the month - so year does not matter.
  // Will not work without year in Firefox
  // Safari only support specific date format
  const dateWithMonth = new Date(`2022/${date.getMonth() + 1}/1`);

  const translatedShortMonth =
    dateWithMonth &&
    dateWithMonth.toLocaleString(language, {
      month: 'short',
    });

  return `${
    translatedShortMonth && stringToPascal(translatedShortMonth)
  } '${date.toLocaleDateString('en', { year: '2-digit' })}`;
};

export const longMonthTranslated = (date: Date, language: Language['shortString'] = 'en') => {
  // We just need the month - so year does not matter.
  // Will not work without year in Firefox
  // Safari only support specific date format
  const dateWithMonth = new Date(`2022/${date.getMonth() + 1}/1`);

  const translatedLongMonth =
    dateWithMonth &&
    dateWithMonth.toLocaleString(language, {
      month: 'long',
    });

  return `${translatedLongMonth && stringToPascal(translatedLongMonth)}`;
};

export const longMonthAndYearTranslated = (
  date: Date,
  language: Language['shortString'] = 'en',
) => {
  // We just need the month - so year does not matter.
  // Will not work without year in Firefox
  // Safari only support specific date format
  const dateWithMonth = new Date(`2022/${date.getMonth() + 1}/1`);

  const translatedLongMonth =
    dateWithMonth &&
    dateWithMonth.toLocaleString(language, {
      month: 'long',
    });

  return `${translatedLongMonth && stringToPascal(translatedLongMonth)} ${date.toLocaleDateString(
    'en',
    { year: 'numeric' },
  )}`;
};
