import { useEffect, useRef, useState } from 'react';
import {
  // useGetRevenueForecast,
  IRevenueForecastItem,
  IRevenueForecastPeriod,
} from 'src/apis/revenueForecastAPI';
import {
  DataGrid,
  dataGridCellBorderLeftThickClassName,
  dataGridCellBorderRightThickClassName,
  dataGridCellFontWeightMediumClassName,
} from 'src/components/mui-components/DataGrid';
import { ResponseHandler } from 'src/components/utils/ResponseHandler';
import {
  GridCellParams,
  GridColDef,
  GridColumnGroupingModel,
  GridPinnedRowsProp,
} from '@mui/x-data-grid-pro';
import { useFilterStore } from 'src/stores/FilterStore';
import {
  longMonthAndYearTranslated,
  longMonthTranslated,
  shortMonthAndYearTranslated,
} from 'src/utils/date/month';
import { Typography } from 'src/components/mui-components';
import { toggleFilterContainerEventName } from 'src/components/layout/FilterLayout/components/MainContainer/components/FilterContainer';
import { useTranslation } from 'react-i18next';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';
import { currencyFormatter } from 'src/utils/currency';
import { useIsFocusStateEnabled } from 'src/stores/FocusStateStore';
import { revenueForecastSelectedFilterListStateKey } from '../localStorageKeys';
import { Toolbar } from './Toolbar';

const slots = {
  toolbar: Toolbar,
};

const periods = [
  {
    startsAt: '2023-03-01T00:00:00',
    endsAt: '2023-03-31T00:00:00',
    type: 'Month',
    identifier: '2023Mar',
  },
  {
    startsAt: '2023-04-01T00:00:00',
    endsAt: '2023-04-30T00:00:00',
    type: 'Month',
    identifier: '2023Apr',
  },
  {
    startsAt: '2023-05-01T00:00:00',
    endsAt: '2023-05-31T00:00:00',
    type: 'Month',
    identifier: '2023May',
  },
  {
    startsAt: '2023-06-01T00:00:00',
    endsAt: '2023-06-30T00:00:00',
    type: 'Month',
    identifier: '2023Jun',
    isCurrentMonth: true,
  },
  {
    startsAt: '2023-07-01T00:00:00',
    endsAt: '2023-07-31T00:00:00',
    type: 'Month',
    identifier: '2023Jul',
  },
  {
    startsAt: '2023-08-01T00:00:00',
    endsAt: '2023-08-31T00:00:00',
    type: 'Month',
    identifier: '2023Aug',
  },
  {
    startsAt: '2023-09-01T00:00:00',
    endsAt: '2023-09-30T00:00:00',
    type: 'Month',
    identifier: '2023Sep',
  },
];

const currentMonth = {
  startsAt: '2023-06-01T00:00:00',
  endsAt: '2023-06-30T00:00:00',
  type: 'Month',
  identifier: '2023Jun',
  isCurrentMonth: true,
};

const previousMonths = [
  {
    startsAt: '2023-03-01T00:00:00',
    endsAt: '2023-03-31T00:00:00',
    type: 'Month',
    identifier: '2023Mar',
  },
  {
    startsAt: '2023-04-01T00:00:00',
    endsAt: '2023-04-30T00:00:00',
    type: 'Month',
    identifier: '2023Apr',
  },
  {
    startsAt: '2023-05-01T00:00:00',
    endsAt: '2023-05-31T00:00:00',
    type: 'Month',
    identifier: '2023May',
  },
];

const futureMonths = [
  {
    startsAt: '2023-07-01T00:00:00',
    endsAt: '2023-07-31T00:00:00',
    type: 'Month',
    identifier: '2023Jul',
  },
  {
    startsAt: '2023-08-01T00:00:00',
    endsAt: '2023-08-31T00:00:00',
    type: 'Month',
    identifier: '2023Aug',
  },
  {
    startsAt: '2023-09-01T00:00:00',
    endsAt: '2023-09-30T00:00:00',
    type: 'Month',
    identifier: '2023Sep',
  },
];

const children: IRevenueForecastItem[] = [
  {
    values: {
      '2023Mar': {
        realized: -5548.66,
      },
      '2023Apr': {
        realized: 1114.58,
      },
      '2023May': {
        realized: -1213.43,
      },
      '2023Jun': {
        realized: 6033.39,
        forecast: 943.97,
        forecastRestOfTheMonth: 6025.97,
      },
      '2023Jul': {
        forecast: 4130.22,
      },
      '2023Aug': {
        forecast: -7137.14,
      },
      '2023Sep': {
        forecast: -1068.34,
      },
    },
    id: '10000',
    projectName: 'Project name 1',
    customer: 'Customer 1',
    projectManager: 'Project Manager 1',
    currency: 'DKK',
    totalRealized: 588.86,
    totalForecast: -9268.23,
    total: 11989.21,
  },
  {
    values: {
      '2023Mar': {
        realized: 7199.29,
      },
      '2023Apr': {
        realized: 11015.69,
      },
      '2023May': {
        realized: 19082.54,
      },
      '2023Jun': {
        realized: 12964.51,
        forecast: 11585.92,
        forecastRestOfTheMonth: 5120.12,
      },
      '2023Jul': {
        forecast: 4572.01,
      },
      '2023Aug': {
        forecast: 13831.9,
      },
      '2023Sep': {
        forecast: -1660.47,
      },
    },
    id: '10001',
    projectName: 'Project name 2',
    customer: 'Customer 2',
    projectManager: 'Project Manager 2',
    currency: 'DKK',
    totalRealized: 8171.96,
    totalForecast: -6649.48,
    total: -5394.31,
  },
  {
    values: {
      '2023Mar': {
        realized: -4224.86,
      },
      '2023Apr': {
        realized: -6222.44,
      },
      '2023May': {
        realized: -5455.89,
      },
      '2023Jun': {
        realized: -8912.82,
        forecast: 19330.67,
        forecastRestOfTheMonth: 10356.68,
      },
      '2023Jul': {
        forecast: 11841.21,
      },
      '2023Aug': {
        forecast: 1893.41,
      },
      '2023Sep': {
        forecast: 12421.77,
      },
    },
    id: '10002',
    projectName: 'Project name 3',
    customer: 'Customer 3',
    projectManager: 'Project Manager 3',
    currency: 'DKK',
    totalRealized: -5857.81,
    totalForecast: -7467.38,
    total: -7799.54,
  },
  {
    values: {
      '2023Mar': {
        realized: -6471.91,
      },
      '2023Apr': {
        realized: 952.56,
      },
      '2023May': {
        realized: -5023.42,
      },
      '2023Jun': {
        realized: -1398.7,
        forecast: 1055.98,
        forecastRestOfTheMonth: -3696.92,
      },
      '2023Jul': {
        forecast: -9505.05,
      },
      '2023Aug': {
        forecast: 17455.41,
      },
      '2023Sep': {
        forecast: 14186.14,
      },
    },
    id: '10003',
    projectName: 'Project name 4',
    customer: 'Customer 4',
    projectManager: 'Project Manager 4',
    currency: 'DKK',
    totalRealized: -8547.21,
    totalForecast: -1872.99,
    total: 18500.13,
  },
  {
    values: {
      '2023Mar': {
        realized: 5130.42,
      },
      '2023Apr': {
        realized: 3449.45,
      },
      '2023May': {
        realized: -4338.05,
      },
      '2023Jun': {
        realized: 12391.05,
        forecast: -3965.52,
        forecastRestOfTheMonth: -3313.04,
      },
      '2023Jul': {
        forecast: 6396.2,
      },
      '2023Aug': {
        forecast: 1054.67,
      },
      '2023Sep': {
        forecast: -5263.94,
      },
    },
    id: '10004',
    projectName: 'Project name 5',
    customer: 'Customer 5',
    projectManager: 'Project Manager 5',
    currency: 'DKK',
    totalRealized: 11900.52,
    totalForecast: -3089.69,
    total: 13640.11,
  },
  {
    values: {
      '2023Mar': {
        realized: 19242.02,
      },
      '2023Apr': {
        realized: 18585.38,
      },
      '2023May': {
        realized: 17990.45,
      },
      '2023Jun': {
        realized: 8278.53,
        forecast: 9542.55,
        forecastRestOfTheMonth: 11836.79,
      },
      '2023Jul': {
        forecast: 12499.3,
      },
      '2023Aug': {
        forecast: 18045.72,
      },
      '2023Sep': {
        forecast: 3516.12,
      },
    },
    id: '10005',
    projectName: 'Project name 6',
    customer: 'Customer 6',
    projectManager: 'Project Manager 6',
    currency: 'DKK',
    totalRealized: 16942.69,
    totalForecast: 5971.22,
    total: -7367.88,
  },
  {
    values: {
      '2023Mar': {
        realized: 18352.62,
      },
      '2023Apr': {
        realized: 12714.4,
      },
      '2023May': {
        realized: 19084.97,
      },
      '2023Jun': {
        realized: 6962.2,
        forecast: 4913.48,
        forecastRestOfTheMonth: 11293.69,
      },
      '2023Jul': {
        forecast: 3573.37,
      },
      '2023Aug': {
        forecast: 3010.75,
      },
      '2023Sep': {
        forecast: 11062.56,
      },
    },
    id: '10006',
    projectName: 'Project name 7',
    customer: 'Customer 7',
    projectManager: 'Project Manager 7',
    currency: 'DKK',
    totalRealized: 14892.92,
    totalForecast: -4105.27,
    total: -9157.07,
  },
  {
    values: {
      '2023Mar': {
        realized: -376.03,
      },
      '2023Apr': {
        realized: 5762.68,
      },
      '2023May': {
        realized: 2653.39,
      },
      '2023Jun': {
        realized: -3193.91,
        forecast: 5675.49,
        forecastRestOfTheMonth: -8709.47,
      },
      '2023Jul': {
        forecast: 14781.78,
      },
      '2023Aug': {
        forecast: 8707.43,
      },
      '2023Sep': {
        forecast: 5027.38,
      },
    },
    id: '10007',
    projectName: 'Project name 8',
    customer: 'Customer 8',
    projectManager: 'Project Manager 8',
    currency: 'DKK',
    totalRealized: 8914.32,
    totalForecast: -5114.48,
    total: 4804.88,
  },
  {
    values: {
      '2023Mar': {
        realized: 14513.62,
      },
      '2023Apr': {
        realized: 19745.23,
      },
      '2023May': {
        realized: 11472.55,
      },
      '2023Jun': {
        realized: -3716.76,
        forecast: -6251.25,
        forecastRestOfTheMonth: 10872.83,
      },
      '2023Jul': {
        forecast: -6653.34,
      },
      '2023Aug': {
        forecast: 6952.29,
      },
      '2023Sep': {
        forecast: 12818.01,
      },
    },
    id: '10008',
    projectName: 'Project name 9',
    customer: 'Customer 9',
    projectManager: 'Project Manager 9',
    currency: 'DKK',
    totalRealized: -5563.98,
    totalForecast: 14422.22,
    total: 1295.5,
  },
  {
    values: {
      '2023Mar': {
        realized: 13880.36,
      },
      '2023Apr': {
        realized: -206.11,
      },
      '2023May': {
        realized: -8738.31,
      },
      '2023Jun': {
        realized: -381.66,
        forecast: 15433.31,
        forecastRestOfTheMonth: 3478.1,
      },
      '2023Jul': {
        forecast: 14282.5,
      },
      '2023Aug': {
        forecast: 493.87,
      },
      '2023Sep': {
        forecast: -1687.45,
      },
    },
    id: '10009',
    projectName: 'Project name 10',
    customer: 'Customer 10',
    projectManager: 'Project Manager 10',
    currency: 'DKK',
    totalRealized: 13310.08,
    totalForecast: -4756.65,
    total: 6268.95,
  },
  {
    values: {
      '2023Mar': {
        realized: -8538.56,
      },
      '2023Apr': {
        realized: 3314.26,
      },
      '2023May': {
        realized: 6354.35,
      },
      '2023Jun': {
        realized: 17286.71,
        forecast: 7872.87,
        forecastRestOfTheMonth: 13385.07,
      },
      '2023Jul': {
        forecast: 13116.49,
      },
      '2023Aug': {
        forecast: 7046.92,
      },
      '2023Sep': {
        forecast: -8157.55,
      },
    },
    id: '100010',
    projectName: 'Project name 11',
    customer: 'Customer 11',
    projectManager: 'Project Manager 11',
    currency: 'DKK',
    totalRealized: -7497.41,
    totalForecast: 10739.5,
    total: 14537.3,
  },
  {
    values: {
      '2023Mar': {
        realized: -492.56,
      },
      '2023Apr': {
        realized: 18112.68,
      },
      '2023May': {
        realized: 637.04,
      },
      '2023Jun': {
        realized: 16607.7,
        forecast: 6080.07,
        forecastRestOfTheMonth: 11588.86,
      },
      '2023Jul': {
        forecast: 17603.52,
      },
      '2023Aug': {
        forecast: 15932.24,
      },
      '2023Sep': {
        forecast: -9158.6,
      },
    },
    id: '100011',
    projectName: 'Project name 12',
    customer: 'Customer 12',
    projectManager: 'Project Manager 12',
    currency: 'DKK',
    totalRealized: -5697.35,
    totalForecast: -8387,
    total: 4853.19,
  },
  {
    values: {
      '2023Mar': {
        realized: 14117.58,
      },
      '2023Apr': {
        realized: -9773.33,
      },
      '2023May': {
        realized: -3666.09,
      },
      '2023Jun': {
        realized: 12128.09,
        forecast: 4790.99,
        forecastRestOfTheMonth: -5992.13,
      },
      '2023Jul': {
        forecast: -2199.05,
      },
      '2023Aug': {
        forecast: 16188.38,
      },
      '2023Sep': {
        forecast: -5258.67,
      },
    },
    id: '100012',
    projectName: 'Project name 13',
    customer: 'Customer 13',
    projectManager: 'Project Manager 13',
    currency: 'DKK',
    totalRealized: 1650.96,
    totalForecast: 4793.95,
    total: 5905.38,
  },
  {
    values: {
      '2023Mar': {
        realized: -2463.17,
      },
      '2023Apr': {
        realized: -7216.79,
      },
      '2023May': {
        realized: -6148.88,
      },
      '2023Jun': {
        realized: 18663.18,
        forecast: 13937.1,
        forecastRestOfTheMonth: -5742.54,
      },
      '2023Jul': {
        forecast: 19726.41,
      },
      '2023Aug': {
        forecast: -2375.02,
      },
      '2023Sep': {
        forecast: -1399.41,
      },
    },
    id: '100013',
    projectName: 'Project name 14',
    customer: 'Customer 14',
    projectManager: 'Project Manager 14',
    currency: 'DKK',
    totalRealized: 14780.85,
    totalForecast: 5938.32,
    total: 18109.69,
  },
  {
    values: {
      '2023Mar': {
        realized: 17408.34,
      },
      '2023Apr': {
        realized: -5246.41,
      },
      '2023May': {
        realized: 15895.59,
      },
      '2023Jun': {
        realized: 4016,
        forecast: -3000.66,
        forecastRestOfTheMonth: 19593.27,
      },
      '2023Jul': {
        forecast: 19437.09,
      },
      '2023Aug': {
        forecast: 4778.73,
      },
      '2023Sep': {
        forecast: -3914.62,
      },
    },
    id: '100014',
    projectName: 'Project name 15',
    customer: 'Customer 15',
    projectManager: 'Project Manager 15',
    currency: 'DKK',
    totalRealized: 2252.19,
    totalForecast: 11711.72,
    total: 2566.87,
  },
  {
    values: {
      '2023Mar': {
        realized: 10358.5,
      },
      '2023Apr': {
        realized: -2006.85,
      },
      '2023May': {
        realized: 713.83,
      },
      '2023Jun': {
        realized: -5895.67,
        forecast: 9368.63,
        forecastRestOfTheMonth: 5063.01,
      },
      '2023Jul': {
        forecast: -5840.95,
      },
      '2023Aug': {
        forecast: -1970.51,
      },
      '2023Sep': {
        forecast: 13458.04,
      },
    },
    id: '100015',
    projectName: 'Project name 16',
    customer: 'Customer 16',
    projectManager: 'Project Manager 16',
    currency: 'DKK',
    totalRealized: -723.75,
    totalForecast: 4357.38,
    total: 8991.87,
  },
  {
    values: {
      '2023Mar': {
        realized: -6724.4,
      },
      '2023Apr': {
        realized: 2209.51,
      },
      '2023May': {
        realized: -7601.35,
      },
      '2023Jun': {
        realized: 16753.99,
        forecast: -2318.38,
        forecastRestOfTheMonth: 17041.16,
      },
      '2023Jul': {
        forecast: 2494.26,
      },
      '2023Aug': {
        forecast: -9674.54,
      },
      '2023Sep': {
        forecast: -8221.23,
      },
    },
    id: '100016',
    projectName: 'Project name 17',
    customer: 'Customer 17',
    projectManager: 'Project Manager 17',
    currency: 'DKK',
    totalRealized: 9957.79,
    totalForecast: -2139.86,
    total: 14760.53,
  },
  {
    values: {
      '2023Mar': {
        realized: -5383.35,
      },
      '2023Apr': {
        realized: 13128,
      },
      '2023May': {
        realized: 14269.5,
      },
      '2023Jun': {
        realized: 6754.42,
        forecast: 17463.67,
        forecastRestOfTheMonth: 3516.75,
      },
      '2023Jul': {
        forecast: 17201.86,
      },
      '2023Aug': {
        forecast: -3380.58,
      },
      '2023Sep': {
        forecast: 4976.11,
      },
    },
    id: '100017',
    projectName: 'Project name 18',
    customer: 'Customer 18',
    projectManager: 'Project Manager 18',
    currency: 'DKK',
    totalRealized: 4017.01,
    totalForecast: 19850.34,
    total: 11014.01,
  },
  {
    values: {
      '2023Mar': {
        realized: 16454.08,
      },
      '2023Apr': {
        realized: 4041.09,
      },
      '2023May': {
        realized: -6990,
      },
      '2023Jun': {
        realized: 4490.89,
        forecast: 55.35,
        forecastRestOfTheMonth: -5218.8,
      },
      '2023Jul': {
        forecast: -813.82,
      },
      '2023Aug': {
        forecast: 11482.67,
      },
      '2023Sep': {
        forecast: 12639.67,
      },
    },
    id: '100018',
    projectName: 'Project name 19',
    customer: 'Customer 19',
    projectManager: 'Project Manager 19',
    currency: 'DKK',
    totalRealized: -9641.07,
    totalForecast: 9571.73,
    total: 6560.58,
  },
  {
    values: {
      '2023Mar': {
        realized: 1165.04,
      },
      '2023Apr': {
        realized: -1713.11,
      },
      '2023May': {
        realized: 7490.62,
      },
      '2023Jun': {
        realized: -6165.91,
        forecast: -3103.81,
        forecastRestOfTheMonth: -2503.84,
      },
      '2023Jul': {
        forecast: 10617.9,
      },
      '2023Aug': {
        forecast: 16702.35,
      },
      '2023Sep': {
        forecast: -618.82,
      },
    },
    id: '100019',
    projectName: 'Project name 20',
    customer: 'Customer 20',
    projectManager: 'Project Manager 20',
    currency: 'DKK',
    totalRealized: 632.47,
    totalForecast: 12304.74,
    total: -4005.85,
  },
  {
    values: {
      '2023Mar': {
        realized: -1391.6,
      },
      '2023Apr': {
        realized: 1150.07,
      },
      '2023May': {
        realized: 10795.45,
      },
      '2023Jun': {
        realized: -4528.08,
        forecast: 7040.72,
        forecastRestOfTheMonth: -4616.78,
      },
      '2023Jul': {
        forecast: 1550.62,
      },
      '2023Aug': {
        forecast: 8452,
      },
      '2023Sep': {
        forecast: 2140.97,
      },
    },
    id: '100020',
    projectName: 'Project name 21',
    customer: 'Customer 21',
    projectManager: 'Project Manager 21',
    currency: 'DKK',
    totalRealized: 17613.9,
    totalForecast: 8872.24,
    total: 5563.61,
  },
  {
    values: {
      '2023Mar': {
        realized: -51.41,
      },
      '2023Apr': {
        realized: 2819.85,
      },
      '2023May': {
        realized: -8940.24,
      },
      '2023Jun': {
        realized: 19458.45,
        forecast: 7034.9,
        forecastRestOfTheMonth: 19351.44,
      },
      '2023Jul': {
        forecast: 3084.64,
      },
      '2023Aug': {
        forecast: 6555.02,
      },
      '2023Sep': {
        forecast: 13098.38,
      },
    },
    id: '100021',
    projectName: 'Project name 22',
    customer: 'Customer 22',
    projectManager: 'Project Manager 22',
    currency: 'DKK',
    totalRealized: 13536.06,
    totalForecast: 10593.37,
    total: -6854.63,
  },
  {
    values: {
      '2023Mar': {
        realized: 8646.32,
      },
      '2023Apr': {
        realized: -4549.19,
      },
      '2023May': {
        realized: -9101.99,
      },
      '2023Jun': {
        realized: 14358.41,
        forecast: -4935.07,
        forecastRestOfTheMonth: 12912.08,
      },
      '2023Jul': {
        forecast: 17368.13,
      },
      '2023Aug': {
        forecast: -7193.3,
      },
      '2023Sep': {
        forecast: 15580.76,
      },
    },
    id: '100022',
    projectName: 'Project name 23',
    customer: 'Customer 23',
    projectManager: 'Project Manager 23',
    currency: 'DKK',
    totalRealized: -8420.59,
    totalForecast: -587.01,
    total: 19611.1,
  },
  {
    values: {
      '2023Mar': {
        realized: 13267.05,
      },
      '2023Apr': {
        realized: 3396.6,
      },
      '2023May': {
        realized: 1447.95,
      },
      '2023Jun': {
        realized: -9414.39,
        forecast: 6519,
        forecastRestOfTheMonth: 5555.42,
      },
      '2023Jul': {
        forecast: -6242.88,
      },
      '2023Aug': {
        forecast: 3968.07,
      },
      '2023Sep': {
        forecast: 6671.67,
      },
    },
    id: '100023',
    projectName: 'Project name 24',
    customer: 'Customer 24',
    projectManager: 'Project Manager 24',
    currency: 'DKK',
    totalRealized: 69.17,
    totalForecast: 4192.91,
    total: 11009.87,
  },
  {
    values: {
      '2023Mar': {
        realized: 3089.37,
      },
      '2023Apr': {
        realized: 11822.25,
      },
      '2023May': {
        realized: 7054.04,
      },
      '2023Jun': {
        realized: 7887.55,
        forecast: 1636.19,
        forecastRestOfTheMonth: 15174.17,
      },
      '2023Jul': {
        forecast: 10570.18,
      },
      '2023Aug': {
        forecast: 16976.82,
      },
      '2023Sep': {
        forecast: 18791.21,
      },
    },
    id: '100024',
    projectName: 'Project name 25',
    customer: 'Customer 25',
    projectManager: 'Project Manager 25',
    currency: 'DKK',
    totalRealized: -1805.8,
    totalForecast: -9863.82,
    total: 4182.64,
  },
  {
    values: {
      '2023Mar': {
        realized: 2918.52,
      },
      '2023Apr': {
        realized: -2023.21,
      },
      '2023May': {
        realized: 8108.05,
      },
      '2023Jun': {
        realized: 1250.26,
        forecast: -5245.75,
        forecastRestOfTheMonth: -7407.02,
      },
      '2023Jul': {
        forecast: 11480.99,
      },
      '2023Aug': {
        forecast: -9267.12,
      },
      '2023Sep': {
        forecast: 17858.47,
      },
    },
    id: '100025',
    projectName: 'Project name 26',
    customer: 'Customer 26',
    projectManager: 'Project Manager 26',
    currency: 'DKK',
    totalRealized: 13813.33,
    totalForecast: -1643.04,
    total: 9409.16,
  },
  {
    values: {
      '2023Mar': {
        realized: -1111.2,
      },
      '2023Apr': {
        realized: 19956.64,
      },
      '2023May': {
        realized: 2931.72,
      },
      '2023Jun': {
        realized: -3474.83,
        forecast: 14166.53,
        forecastRestOfTheMonth: -3618.27,
      },
      '2023Jul': {
        forecast: 457.94,
      },
      '2023Aug': {
        forecast: 13252.31,
      },
      '2023Sep': {
        forecast: 18458.78,
      },
    },
    id: '100026',
    projectName: 'Project name 27',
    customer: 'Customer 27',
    projectManager: 'Project Manager 27',
    currency: 'DKK',
    totalRealized: 5045.47,
    totalForecast: 545.81,
    total: -2190.37,
  },
  {
    values: {
      '2023Mar': {
        realized: 11179.89,
      },
      '2023Apr': {
        realized: -6341.1,
      },
      '2023May': {
        realized: 4839.17,
      },
      '2023Jun': {
        realized: 12588.68,
        forecast: 123.99,
        forecastRestOfTheMonth: -7896.66,
      },
      '2023Jul': {
        forecast: 7955.75,
      },
      '2023Aug': {
        forecast: 12659.83,
      },
      '2023Sep': {
        forecast: 736.57,
      },
    },
    id: '100027',
    projectName: 'Project name 28',
    customer: 'Customer 28',
    projectManager: 'Project Manager 28',
    currency: 'DKK',
    totalRealized: 15340.49,
    totalForecast: -3745.04,
    total: 15214.7,
  },
  {
    values: {
      '2023Mar': {
        realized: 17283.09,
      },
      '2023Apr': {
        realized: -1929.19,
      },
      '2023May': {
        realized: 3218.11,
      },
      '2023Jun': {
        realized: 18379.76,
        forecast: 16248.03,
        forecastRestOfTheMonth: -2830.99,
      },
      '2023Jul': {
        forecast: 15478.24,
      },
      '2023Aug': {
        forecast: 6968.59,
      },
      '2023Sep': {
        forecast: 17778.62,
      },
    },
    id: '100028',
    projectName: 'Project name 29',
    customer: 'Customer 29',
    projectManager: 'Project Manager 29',
    currency: 'DKK',
    totalRealized: -9975.92,
    totalForecast: -1545.93,
    total: -1491.12,
  },
  {
    values: {
      '2023Mar': {
        realized: 359.33,
      },
      '2023Apr': {
        realized: 14898.97,
      },
      '2023May': {
        realized: -3338.56,
      },
      '2023Jun': {
        realized: 8908.32,
        forecast: 9227.42,
        forecastRestOfTheMonth: 19958.81,
      },
      '2023Jul': {
        forecast: -2047.3,
      },
      '2023Aug': {
        forecast: 8676.78,
      },
      '2023Sep': {
        forecast: 2566.88,
      },
    },
    id: '100029',
    projectName: 'Project name 30',
    customer: 'Customer 30',
    projectManager: 'Project Manager 30',
    currency: 'DKK',
    totalRealized: 8677.84,
    totalForecast: 6744.07,
    total: 7390.47,
  },
];

const summations = [
  {
    values: {
      '2023Mar': {
        realized: -8738.92,
      },
      '2023Apr': {
        realized: 2845.09,
      },
      '2023May': {
        realized: 12988.73,
      },
      '2023Jun': {
        realized: 13601.54,
        forecast: 6274.15,
        forecastRestOfTheMonth: 11518.58,
      },
      '2023Jul': {
        forecast: 7237.86,
      },
      '2023Aug': {
        forecast: -9532.34,
      },
      '2023Sep': {
        forecast: -4246.4,
      },
    },
    id: 'TOTAL_NOT_IN_RESOURCE_PLAN',
    projectName: '',
    customer: '',
    projectManager: '',
    currency: '',
    totalRealized: 16235.04,
    totalForecast: -2145.58,
    total: 4639.1,
  },
  {
    values: {
      '2023Mar': {
        realized: 3094.61,
      },
      '2023Apr': {
        realized: 12923.48,
      },
      '2023May': {
        realized: 16293.17,
      },
      '2023Jun': {
        realized: 16910.53,
      },
      '2023Jul': {},
      '2023Aug': {},
      '2023Sep': {},
    },
    id: 'TOTAL',
    projectName: '',
    customer: '',
    projectManager: '',
    currency: '',
    totalRealized: 16143.91,
    totalForecast: 8019.39,
    total: 16343.9,
  },
  {
    values: {
      '2023Mar': {
        realized: -7292.42,
      },
      '2023Apr': {
        realized: 17309.5,
      },
      '2023May': {
        realized: 347.24,
      },
      '2023Jun': {
        forecastRestOfTheMonth: 19106.24,
      },
      '2023Jul': {
        forecast: -5160.03,
      },
      '2023Aug': {
        forecast: 9183.87,
      },
      '2023Sep': {
        forecast: 7077.04,
      },
    },
    id: 'TOTAL_ACCUMULATED',
    projectName: '',
    customer: '',
    projectManager: '',
    currency: '',
    totalRealized: 6467.88,
    totalForecast: -3858.21,
    total: 3963.14,
  },
];

export const Table = ({ selectedViewOptions }: { selectedViewOptions: any }) => {
  const { currentLanguage } = useGetCurrentLanguage();
  const { t } = useTranslation('revenueForecast');
  const { filterQueryObj } = useFilterStore();
  localStorage.setItem(revenueForecastSelectedFilterListStateKey, JSON.stringify(filterQueryObj));

  // const {
  //   isLoading = false,
  //   isSuccess = true,
  //   isError = false,
  //   isEmpty = false,
  //   periods,
  //   currentMonth,
  //   previousMonths,
  //   futureMonths,
  //   children,
  //   summations,
  // } = useGetRevenueForecast({ selectedFilterList: filterQueryObj }, selectedViewOptions);

  // eslint-disable-next-line no-console
  console.log('periods', periods, selectedViewOptions);

  // eslint-disable-next-line no-console
  console.log('currentMonth', currentMonth);

  // eslint-disable-next-line no-console
  console.log('previousMonths', previousMonths);

  // eslint-disable-next-line no-console
  console.log('futureMonths', futureMonths);
  // eslint-disable-next-line no-console
  console.log('children', children);
  // eslint-disable-next-line no-console
  console.log('summations', summations);

  const rows: IRevenueForecastItem[] = [...children];

  const sharedColPeriodSettings: Omit<GridColDef, 'field'> = {
    hideable: false,
    minWidth: 135,
    type: 'number',
    headerAlign: 'right',
    valueFormatter: (value) => currencyFormatter(currentLanguage).format(value ?? 0),
  };

  const columns: GridColDef[] = [
    {
      field: 'projectName',
      hideable: false,
      headerName: t('TableHeaderProjectName'),
      minWidth: 175,
      colSpan: (value, row) => {
        if (
          row?.id === 'TOTAL' ||
          row?.id === 'TOTAL_ACCUMULATED' ||
          row?.id === 'TOTAL_NOT_IN_RESOURCE_PLAN'
        ) {
          return 4;
        }
        return undefined;
      },
      valueGetter: (value, row) => {
        if (row?.id === 'TOTAL') {
          return t('TableRowTotal');
        }
        if (row?.id === 'TOTAL_NOT_IN_RESOURCE_PLAN') {
          return t('TableRowTotalNotInResourcePlanner');
        }
        if (row?.id === 'TOTAL_ACCUMULATED') {
          return t('TableRowTotalAccumulated');
        }
        return row?.projectName;
      },
    },
    { field: 'customer', headerName: t('TableHeaderCustomer'), minWidth: 175 },
    {
      field: 'projectManager',
      headerName: t('TableHeaderProjectManager'),
      minWidth: 175,
    },
    {
      field: 'currency',
      headerName: t('TableHeaderCurrency'),
      minWidth: 80,
    },

    ...periods
      .map((period: IRevenueForecastPeriod, index: number) => {
        const { isCurrentMonth } = period;
        let currentMonthColumns: GridColDef[] = [];

        if (isCurrentMonth) {
          currentMonthColumns = [
            {
              field: `${period.identifier}Realized`,
              headerName: `Until yesterday`,
              description: t('TableHeaderRealizedRevenueDescription'),
              valueGetter: (value, row) => row?.values[period.identifier].realized,
              headerClassName: `${dataGridCellBorderRightThickClassName}`,
              ...sharedColPeriodSettings,
              headerAlign: 'right',
            },
            {
              field: `${period.identifier}ForecastRestOfMonth`,
              headerName: 'Rest of month',
              description: t('TableHeaderRemainingEstimatedRevenueDescription'),
              valueGetter: (value, row) => row?.values[period.identifier].forecastRestOfTheMonth,
              headerClassName: `${dataGridCellBorderRightThickClassName}`,
              ...sharedColPeriodSettings,
              headerAlign: 'right',
            },
            {
              field: `${period.identifier}ForecastMonth`,
              headerName: `${longMonthTranslated(
                new Date(period.startsAt),
                currentLanguage,
              )} forecast`,
              description: t('TableHeaderEstimatedMonthlyRevenueCurrentMonthDescription'),
              valueGetter: (value, row) => row?.values[period.identifier].forecast,
              headerClassName: `${dataGridCellBorderRightThickClassName}`,
              ...sharedColPeriodSettings,
              cellClassName: `${dataGridCellBorderRightThickClassName} ${sharedColPeriodSettings.cellClassName}`,
            },
          ];
          return [...currentMonthColumns];
        }

        return {
          field: `${period.identifier}Realized`,
          headerName: `${shortMonthAndYearTranslated(new Date(period.startsAt), currentLanguage)}`,
          description: longMonthAndYearTranslated(new Date(period.startsAt), currentLanguage),
          valueGetter: (value: never, row: IRevenueForecastItem) =>
            row?.values[period.identifier].realized ?? row?.values[period.identifier].forecast,
          headerClassName: `${dataGridCellBorderRightThickClassName} ${
            index === 0 && dataGridCellBorderLeftThickClassName
          }`,
          ...sharedColPeriodSettings,
          cellClassName: `${
            sharedColPeriodSettings.cellClassName
          } ${dataGridCellBorderRightThickClassName} ${
            index === 0 && dataGridCellBorderLeftThickClassName
          }`,
        };
      })
      .flat(),
    {
      field: 'totalRealized',
      type: 'number',
      valueFormatter: ({ value }) => currencyFormatter(currentLanguage).format(value ?? 0),
      headerName: t('TableHeaderTotalRealizedRevenue'),
      description: t('TableHeaderTotalRealizedRevenueDescription'),
      minWidth: 100,
    },
    {
      field: 'totalForecast',
      type: 'number',
      valueFormatter: (value) => currencyFormatter(currentLanguage).format(value ?? 0),
      headerName: t('TableHeaderTotalEstimatedRevenue'),
      description: t('TableHeaderTotalEstimatedRevenueDescription'),
      minWidth: 100,
    },
    {
      field: 'total',
      hideable: false,
      type: 'number',
      headerName: t('TableHeaderTotal'),
      minWidth: 100,
      headerClassName: dataGridCellBorderLeftThickClassName,
      cellClassName: ({ id }) => {
        const classNames = dataGridCellFontWeightMediumClassName;
        if (id === 'TOTAL') {
          return `${classNames} ${dataGridCellBorderLeftThickClassName}`;
        }

        return classNames;
      },
      renderCell: ({ value, id }) => {
        if (id === 'TOTAL') {
          return (
            <Typography fontWeight={700} variant="numeric">
              {value ? currencyFormatter(currentLanguage).format(value ?? 0) : ''}
            </Typography>
          );
        }

        return currencyFormatter(currentLanguage).format(value ?? 0);
      },
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'informationGroup',
      headerName: '',
      headerAlign: 'center',
      children: [
        { field: 'projectName' },
        { field: 'customer' },
        { field: 'projectManager' },
        { field: 'currency' },
      ],
    },
    {
      groupId: 'realizedGroup',
      headerName: t('TableHeaderRealizedMonthlyRevenue'),
      description: t('TableHeaderRealizedRevenueDescription'),
      headerAlign: 'center',
      headerClassName: `${dataGridCellBorderRightThickClassName} ${dataGridCellBorderLeftThickClassName}`,
      children: previousMonths.map((period) => ({
        field: `${period.identifier}Realized`,
      })),
    },
    {
      groupId: 'currentMonthGroup',
      headerName: `${shortMonthAndYearTranslated(
        new Date(currentMonth?.startsAt),
        currentLanguage,
      )} (${t('TableGroupHeaderCurrentMonth')})`,
      headerClassName: dataGridCellBorderRightThickClassName,
      description: longMonthAndYearTranslated(new Date(currentMonth?.startsAt), currentLanguage),
      headerAlign: 'center',
      children: [
        { field: `${currentMonth?.identifier}Realized` },
        { field: `${currentMonth?.identifier}ForecastRestOfMonth` },
        { field: `${currentMonth?.identifier}ForecastMonth` },
      ],
    },
    {
      groupId: 'forecastGroup',
      headerName: t('TableHeaderEstimatedMonthlyRevenue'),
      description: t('TableHeaderEstimatedMonthlyRevenueFutureMonthDescription'),
      headerAlign: 'center',
      headerClassName: dataGridCellBorderRightThickClassName,
      children: futureMonths.map((period) => ({
        field: `${period.identifier}Realized`,
      })),
    },
    {
      groupId: 'totalGroup',
      headerName: '',
      headerAlign: 'center',
      description: '',
      children: [{ field: 'totalRealized' }, { field: 'totalForecast' }, { field: 'total' }],
    },
  ];

  const pinnedRows: GridPinnedRowsProp = {
    bottom: [...summations],
  };

  // Height of the table
  const dataGridContainerRef = useRef<HTMLDivElement>(null);
  const [offSetTop, setOffSetTop] = useState<number>(0);
  const isFocusStateEnabled = useIsFocusStateEnabled();

  // Set on initial load
  useEffect(() => {
    if (dataGridContainerRef.current) {
      setOffSetTop(dataGridContainerRef.current.offsetTop);
    }
  }, []);

  const listener = (ref: HTMLDivElement | null) => {
    if (ref) {
      setTimeout(() => {
        setOffSetTop(ref.offsetTop);
      }, 1000);
    }
  };

  // Set on FilterContainer toggle
  useEffect(() => {
    const ref = dataGridContainerRef.current;
    window.addEventListener(toggleFilterContainerEventName, () => listener(ref));

    return () => {
      window.removeEventListener(toggleFilterContainerEventName, () => listener(ref));
    };
  }, [dataGridContainerRef, isFocusStateEnabled]);

  return (
    <ResponseHandler isLoading={false} isEmpty={false} isError={false}>
      <div
        style={{
          width: '100%',
          height: isFocusStateEnabled ? 'calc(100vh - 82px)' : `calc(100vh - ${offSetTop + 20}px)`,
        }}
        ref={dataGridContainerRef}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          columnGroupingModel={columnGroupingModel}
          pinnedRows={summations.length > 0 ? pinnedRows : undefined}
          disableColumnMenu
          slots={slots}
          disableRowSelectionOnClick
          pinnedColumns={{ left: ['projectName'], right: ['total'] }}
          hideFooter
          getCellClassName={(params: GridCellParams<any, any, number>) => {
            if (!Number.isNaN(params.value) && params.value < 0) {
              return 'error';
            }

            return '';
          }}
          data-automation-id="RevenueForecastTable"
        />
      </div>
    </ResponseHandler>
  );
};
