import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IMigrationStatusResponse } from '../../types';

const REVENUE_FORECAST_MIGRATION_STATUS_URL_PATH = '/api/v2/budget-forecasting/migration-status';
export const REVENUE_FORECAST_MIGRATION_STATUS_MSW_STATUS_KEY = 'GetRevenueForecastMigrationStatus';

export const REVENUE_FORECAST_MIGRATION_STATUS_KEY = 'REVENUE_FORECAST_MIGRATION_STATUS';

const revenueForecastFetch = (): Promise<IMigrationStatusResponse> =>
  getFetch({
    path: REVENUE_FORECAST_MIGRATION_STATUS_URL_PATH,
    key: REVENUE_FORECAST_MIGRATION_STATUS_MSW_STATUS_KEY,
  });

const useGetMigrationStatusQuery = () =>
  useQuery([REVENUE_FORECAST_MIGRATION_STATUS_KEY], revenueForecastFetch, {
    refetchOnMount: false,
  });

export const useGetRevenueForecastMigrationStatus = () => {
  const { data, ...restProps } = useGetMigrationStatusQuery();

  return {
    tenantId: data?.model?.properties?.tenantId ?? '',
    isDataSynchronizationEnabled: data?.model?.properties?.isDataSynchronizationEnabled ?? false,

    isMigrated: data?.model?.properties?.isMigrated ?? false,
    canTriggerMigration: data?.model?.properties?.canTriggerMigration ?? false,
    canAddResourcePlan: data?.model?.properties?.canAddResourcePlan ?? false,
    isResourcePlanMigrated: data?.model?.properties?.isResourcePlanMigrated ?? false,
    ...restProps,
  };
};
